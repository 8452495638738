<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    submit-title="Contact support"
    title="Dear user!"
    width="450"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row">
      Your account has been expired/deactivated. Please contact support to
      continue your subscription
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  methods: {
    onSubmit() {
      const emailAddress = 'support@glprotocol.com'
      const subject = 'Account has been expired/deactivated'

      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject,
      )}`

      window.location.href = mailtoLink
    },
  },
}
</script>
